:root {
  --map-pin: #000000;
  --pin-width: 4rem;
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 20vh;
  position: relative;
}

.pin {
  position: absolute;
  top: calc((var(--pin-width) / 2 * -1) - 0.5rem);
  left: calc(var(--pin-width) / 2 * -1);
  display: flex;
  align-items: center;
  width: var(--pin-width);
  color: var(--map-pin);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

/*@media screen and (min-width: 1024px) {
  .google-map {
    width: 50%;
    min-height: 20vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}
*/

/*# sourceMappingURL=map.css.map */
