:root {
  --header-height: 7vh;
}

@font-face {
  font-family: "Lufga";
  src: url("./fonts/lufga_fonts/Lufga-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Lufga-bold";
  src: url("./fonts/lufga_fonts/Lufga-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Metrisch-light";
  src: url("./fonts/metrisch/Metrisch-Light-webfont.ttf") format("truetype");
}
@font-face {
  font-family: "Metrisch-book";
  src: url("./fonts/metrisch/Metrisch-Book-webfont.ttf") format("truetype");
}
@font-face {
  font-family: Origin;
  src: url("./fonts/OriginSansRegular.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  min-width: 100vw;
  min-height: 100vh;
}

p {
  margin: 0;
  padding: 0;
}

body {
  max-height: 100vh;
  width: 100vw;
  background-image: radial-gradient(circle farthest-corner at 10% 20%, #a4d0ab 0%, #6695d0 100.2%);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.image-side-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-side {
  max-width: 30rem;
  padding: 0 3rem 2rem 0;
}

.image-first-page {
  max-width: 50rem;
  padding: 0;
}

.can-click {
  cursor: pointer;
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: calc(97% - var(--header-height));
  justify-content: space-between;
}

.content-external-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-side-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.first-page-content {
  width: 100%;
  justify-content: center;
  flex-direction: row;
}

.first-page-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container {
  width: 100vw;
  height: 97vh;
  background-color: #f1f1f1;
  filter: drop-shadow(0 0 0.8em rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  aspect-ratio: 16/9;
  overflow-y: scroll;
  overflow-x: hidden;
}

.card-header {
  padding: 0.5rem;
  text-align: center;
  width: 100%;
  margin: 0 2rem;
  /* border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, $regular-green, $regular-blue);*/
}

h1 {
  margin: 1rem;
  font-size: 4em;
  font-family: Lufga-bold, "Roboto Light", sans-serif;
}

h2 {
  font-family: Lufga, "Roboto Light", sans-serif;
}

.special-title {
  font-family: Origin, sans-serif !important;
  text-transform: uppercase;
  font-size: 8em;
  letter-spacing: 4px;
  word-spacing: -15px;
  color: black;
  text-shadow: -1px -1px 0 #6695d0, -2px -2px 0 #6695d0, -3px -3px 0 #a4d0ab, -4px -4px 0 #a4d0ab, -5px -5px 0 #a4d0ab, -6px -6px 0 rgba(102, 149, 208, 0.4);
}

.changes-to-special-title {
  transform: skewY(0);
  text-shadow: -1px -1px 0 #6695d0, -2px -2px 0 #6695d0, -3px -3px 0 #a4d0ab, 3px 3px 0 #a4d0ab;
}

.separator {
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #a4d0ab, #6695d0);
  border-width: 0 0 1px 0;
}

.separator:first-child {
  border-top: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #a4d0ab, #6695d0);
}

.card-content {
  display: flex;
  width: 50%;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  align-items: center;
  padding: 1rem;
  font-size: 1.2em;
  justify-content: center;
  max-height: 43vh;
}

.section-content {
  width: 100%;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
}

.input-field {
  margin: 0 0.5rem;
  padding: 0.2rem;
  text-align: center;
  border-width: 0 0 1px;
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #a4d0ab, #6695d0);
  background-color: transparent;
}

.input-confirm-registry {
  width: 20rem;
}

.card-footer {
  margin-top: 2rem;
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regular-button {
  background-image: radial-gradient(circle farthest-corner at 10% 20%, #a4d0ab 0%, #6695d0 100.2%);
  border: none;
  margin: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  filter: drop-shadow(0 0 0.8em rgba(0, 0, 0, 0.2));
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.regular-button:hover {
  filter: drop-shadow(0 0.3em 0.8em rgba(0, 0, 0, 0.4));
  transition: 0.3s;
  -webkit-transition: 0.3s;
  cursor: pointer;
}
.otp-button{
  margin-bottom: 0.2rem;
  margin-left:0px;
  background: white !important;
  float: right;
}
.choice-button {
  background: linear-gradient(#f1f1f1, #f1f1f1) padding-box, linear-gradient(to right, #6695d0, #a4d0ab) border-box;
  color: #313149;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  display: inline-block;
}

.choice-button:hover {
  filter: drop-shadow(0 0 0.8em rgba(0, 0, 0, 0.3));
  transition: 0.3s;
  -webkit-transition: 0.3s;
  cursor: pointer;
}

.form-card-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.registry-list {
  margin-top: 1rem;
  height: 15rem;
  overflow: scroll;
  width: 80%;
}

.form-data-and-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 1rem;
  max-height: 60vh;
  overflow: scroll;
}

.form-confirm-page {
  display: flex;
  flex-direction: column;
}

.element-in-confirm-data-page {
  width: calc(100% / 2) !important;
  height: 100%;
}

.element-in-confirm-data-page:first-child {
  padding-left: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 50vh;
  overflow: scroll;
}

.element-in-confirm-data-page:last-child {
  padding-right: 10rem;
}

.active-selected-business {
  background-color: rgba(102, 149, 208, 0.4);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  border-radius: 0.3em;
  border: none;
}

.hetamap-inf-name-sec1{
  font-size: 9px;
  text-align: left;
  padding-left: 4px;
  text-align: right;
  padding-right: 15px;
  margin-top: -5px;
}
.businesses-found {
  display: flex;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.business-info {
  margin-left: 1.5rem;
}

.businesses-found:hover {
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.search-results-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.external-header-credits {
  height: var(--header-height);
}

.external-header-credits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding-left: 1rem;
  margin-bottom: 20px;
}

.external-footer-credits {
  display: flex;
  justify-content: flex-end;

}

.center-of-footer {
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-name {
  font-family: "Lufga-bold", sans-serif;
  background: -webkit-linear-gradient(#6695d0, #a4d0ab);
  filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, 0.02));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2em;
}

.bnl-logo,
.cargeas-logo {
  max-width: 10rem;
  max-height: 5rem;
  margin: 1.5rem 1rem;
  z-index: 2;
}

.riskapp-footer {
  margin: 1rem 1rem 0 0;
  align-items: center;
  display: flex;
  height: 100%;
  z-index: 2;
}

.riskapp-logo {
  width: 6rem;
  max-height: 3rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
.chart-image{
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.chart-text{
  width: 100%;
  height: 100%;
  padding-left: 20px;
  display: inline;
  vertical-align: text-top;
  padding-top: 7px;
}
.fade-in-animation {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}

.user-info-internal-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 2rem;
  height: 100%;
  justify-content: center;
  border-right: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #a4d0ab, #6695d0);
  border-width: 0 1px 0 0;
}

.wizard-header-container {
  position: fixed;
  width: calc(100vw - 17%);
  right: 0;
  display: flex;
  justify-content: center;
  height: auto;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.wizard-header-container-full-width {
  width: 100vw;
}

.positioning-of-progress-bar {
  width: 35rem;
  margin: 0 auto;
}

.wizard-text-step {
  position: fixed;
  top: 2rem;
  color: grey;
  width: 5rem;
  text-align: center;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.wizard-text-step-active {
  color: black;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.RSPBprogressBar {
  height: 0.4rem !important;
}

.ant-steps-item-content {
  display: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.policy-card-container {
  background-color: #f1f1f1;
  padding: 0.2rem 1rem;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.2));
  -webkit-box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  width: calc(100% / 3 - 2.5rem);
}
.chart-card-container {
  background-color: #f1f1f1;
  padding: 0.2rem 1rem;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  width: calc(100% / 3 - 2.5rem);
}

.policy-card-body {
  padding: 0 1.5rem;
}

.risks-image {
  max-width: 40rem;
  cursor: pointer;
}

#first-risk {
  position: relative;
}
.sv_qstn .sv_nav .sv_row .sv_q .sv_qstn{
  background-color: '#f1f1f1' !important;
}

.sv_body{
  padding: 0px !important;

}
.sv_qstn{
  background-color: '#f1f1f1' !important;

}
.heatmap-inf{
  border: 3px black solid;
  width: 24%;
  display: inline-block;
  height: 50px;
}
.heatmap-inf-main{
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  display: inline-flex;
  width:98%;
  margin-left: 6px;
}

.hetamap-inf-name{
  font-size: 9px;
  text-align: left;
  padding-left: 4px;
}
.hetamap-inf-name-sec{
  font-size: 9px;
  text-align: left;
  padding-left: 4px;
  text-align: right;
  padding-right: 15px;
  padding-top: 10px;
}
.hetamap-inf-name-sec1{
  font-size: 9px;
  text-align: left;
  padding-left: 4px;
  text-align: right;
  padding-right: 15px;
  margin-top: -5px;
}
.hetamap-inf-scale{
  font-size: 7px;
  text-align: left;
  padding-left: 4px;
}
.hetamap-inf-num{
  font-size: 8px;
  text-align: left;
  padding-left: 4px;
  padding-top: 7px;
  float: right;
  padding-right: 8px;
  font-size: 20px;
}
.heatmap-container{
  width:'100%';
  margin-top:'20px'
}
.heatmap-main-chart{
  font-size: "13px";
  width:'664px';
  margin-left:'-32px'
}
.heatmap-legend-box{
  width:'45px';
  height:'15px';
  margin-left:'5px'
}
.chart-main{
  width: 100%;
}

/*
#first-risk:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 55%;
  bottom: 0;
  background: -webkit-linear-gradient($opacity-green, $opacity-blue) left repeat;
  background: linear-gradient($opacity-green, $opacity-blue) left repeat;
}*/
.risk-point {
  width: 30px;
  height: 30px;
  border: none;
  background-color: #ff6347;
  border-radius: 30px;
  position: absolute;
  z-index: 10;
  filter: opacity(70%);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  animation: active-risk 1.5s infinite;
  box-shadow: 0 0 0 0 #ff8c77;
}

.risk-point:hover {
  filter: opacity(100%);
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.risk-info {
  background-color: black;
  width: 15rem;
  position: absolute;
  color: white;
  border-radius: 5px;
  left: 35%;
  top: -30px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  z-index: 11;
  padding: 0.5rem;
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: #0074d9;
}

@keyframes active-risk {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #f36349;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(50, 205, 50, 0);
  }
}
.typeform-container {
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.typeform-not-ready {
  height: 0;
  width: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.policies-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.policy-card-container:first-child {
  margin-left: 1.2rem;
}

.policy-card-container:hover {
  transform: scale(1.05, 1.05);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  cursor: pointer;
  z-index: 10;
}

.policy-card-header {
  text-align: center;
  border-radius: 0.4em;
  margin-bottom: 0.5rem;
}

.selected-policy {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  /* background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    $opacity-blue 0%,
    $opacity-green 100.2%
  );*/
  filter: drop-shadow(0 0 0.5rem #6695d0);
  box-shadow: drop-shadow(0 0 0.5rem #6695d0);
  -webkit-box-shadow: drop-shadow(0 0 0.5rem #6695d0);
}

.barred-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.barred {
  color: tomato;
  text-decoration: none;
  position: relative;
}
.barred:before {
  content: " ";
  display: block;
  width: 90%;
  border-top: 2px solid rgba(255, 99, 71, 0.8);
  height: 10px;
  position: absolute;
  top: 0.8em;
  left: 0.4rem;
  transform: rotate(-10deg);
}

.prices-container {
  display: flex;
  justify-content: center;
  flex: none;
  flex-direction: column;
}

.prices-container > h1 {
  margin: 0 1rem;
}

.barred-price {
  font-size: 0.4em;
  padding-bottom: 0;
}

.regular-price {
  font-size: 3em;
  padding-top: 0;
}

.prices-whole {
  display: flex;
  justify-content: center;
}

.decimals {
  font-size: 0.5em;
}

.policy-name {
  font-family: "Lufga-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 1.5em;
  margin-bottom: 0;
}
.chart-name {
  font-family: "Lufga-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 1.5em;
  margin-bottom: 0;
  font-size: 30px;
}
.ant-table-content {
  background-color: #f1f1f1;
  -webkit-box-shadow:0 0 0.1rem rgba(0, 0, 0, 0.2);
 }
.sv_body{
   border-top: none !important;
 }
.policy-title {
  font-family: "Lufga-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: left;
}
.is-covered {
  color: seagreen;
}

.not-covered {
  color: tomato;
}

.crossed-out {
  text-decoration: line-through;
}

.monthly-price {
  padding: 0 1rem;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 0.8em;
}

.monthly {
  font-size: 0.8em;
}

.policy-info-row {
  padding: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3em;
}

.wizard-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.user-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 1.3em;
}

.user-data-business-name {
  font-size: 1.85em;
}

.user-data-lesser-info {
  font-size: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  color: #6695d0 !important;
}

.left-side-inside-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.text-last-page {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pdf-container {
  height: 54vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.stripe-preview {
  height: 40vh;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}

@media screen and (min-width: 1550px) {
  .image-side-container {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1024px) and (max-height: 990px) {
  .positioning-of-progress-bar {
    width: 25rem;
    top: 30rem;
  }

  .user-info-internal-container {
    padding-left: 1rem;
  }
}
@media screen and (max-width: 1366px) and (max-height: 768px) {
  .card-header > h1 {
    font-size: 2.5em;
  }

  .card-header {
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  }

  .policies-list {
    overflow: scroll;
  }

  .positioning-of-progress-bar {
    width: 25rem;
    top: 30rem;
  }

  .positioning-of-progress-bar {
    top: 25rem;
    width: 30rem;
  }

  .card-header-policies {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-header-policies > h1 {
    margin-top: 0;
  }

  .button-show-policies {
    margin-top: 0.5rem !important;
  }
}
@media screen and (max-height: 1130px) {
  .policies-list {
    overflow: scroll;
  }

  .policy-info-row {
    font-size: 1em;
  }

  .monthly-price {
    padding: 0 0.5rem;
    font-size: 1.7em;
    line-height: 0.7em;
  }
}
@media screen and (max-width: 1250px) and (max-height: 1050px) {
  .card-header > h1 {
    font-size: 1.6em;
  }

  .policy-card-container {
    width: calc(100% / 3 - 2rem);
  }

  .image-side {
    max-width: 19rem;
  }

  .policies-list {
    overflow: scroll;
  }

  .positioning-of-progress-bar {
    top: 28rem;
    width: 27rem;
  }

  .form-data-and-image-container {
    max-height: 40vh;
  }

  .element-in-confirm-data-page:first-child {
    padding-left: 10rem;
  }

  .element-in-confirm-data-page:last-child {
    padding-right: 5rem;
  }
}
@media screen and (min-width: 701px) and (max-width: 1249px) {
  .risks-image {
    width: 30rem;
  }
}
@media screen and (max-height: 1000px) {
  .element-in-confirm-data-page:first-child {
    max-height: 45vh;
    overflow: scroll;
  }

  .image-side {
    width: 22rem;
  }
}
@media screen and (max-width: 810px) {
  .risks-image {
    width: 25rem;
  }
}
@media screen and (max-width: 700px) {
  .content {
    flex-direction: column;
  }

  .section-content {
    flex-direction: column;
    align-items: center;
  }

  .left-side-inside-content {
    flex-direction: column;
  }

  .image-side {
    max-width: 20rem !important;
  }
}
@media screen and (max-width: 685px) {
  html,
body {
    width: 100vw;
    height: 100vh;
  }

  .left-side-content {
    width: 100%;
  }

  .image-side-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .image-side {
    max-width: 20rem;
    padding: 0;
    margin: 0.5rem;
  }

  .content {
    height: 100%;
    overflow: scroll;
    justify-content: center;
  }

 

  .left-side-content {
    padding: 0;
  }

  .RSPBprogressBar {
    transform: unset;
  }

  .indexedStep {
    transform: unset;
  }

  .user-info-internal-container {
    width: 90%;
  }

  .user-data {
    display: none;
  }

  .positioning-of-progress-bar {
    z-index: 10;
    top: 5.5rem;
    width: 90%;
  }

  .left-side-content {
    justify-content: center;
    padding: 3rem 0 0 0;
  }

  .first-page-content {
    height: 60%;
  }

  .risks-image {
    width: 20rem;

  }

  .policies-container {
    justify-content: unset;
    overflow: scroll;
    height: 70vh;
    flex-direction: column;
    align-items: center;
  }

  .policy-card-container {
    margin: 1rem 0;
    width: 80vw;
  }

  .policy-card-container:hover {
    transform: unset;
    transition: 0.3s;
    cursor: pointer;
    z-index: 10;
  }

  .form-card-content {
    overflow: scroll;
  }

  .form-data-and-image-container {
    flex-direction: column;
  }

  .left-side-inside-content {
    height: 80vh;
  }

  .typeform-container {
    height: 100%;
    width: 100%;
    padding: 0 1rem;
  }

  .policy-card-container:hover {
    transform: unset;
    transition: 0.3s;
    cursor: pointer;
    z-index: unset;
  }
  .survay-main{
    width:'100%';
    margin-top:'50px';
    overflow:'scroll'
  }
}

/*# sourceMappingURL=customer-journey.css.map */
