@font-face {
  font-family: "Lufga";
  src: url("./fonts/lufga_fonts/Lufga-Regular.otf") format("opentype");
}
.switch {
  position: relative;
  display: inline-block;
  width: 8rem;
  height: 2rem;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a4d0ab;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.8rem;
  width: 1.8rem;
  left: 0.1rem;
  bottom: 0.1rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6695d0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(6rem);
  -ms-transform: translateX(6rem);
  transform: translateX(6rem);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: "MENSILE";
  color: black;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Lufga, sans-serif;
  letter-spacing: 1.5px;
}

input:checked + .slider:after {
  color: white;
  content: "ANNUALE";
}

/*--------- END --------*/

/*# sourceMappingURL=switch.css.map */
